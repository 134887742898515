/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API_URL } from '@/constants/env';
import { StorageKeys } from '@/constants/misc';
import type { DecodedJWT } from '@/types/jwt';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import { useMultipleProfiles } from '@/store/useMultipleProfiles';

export interface FetcherArgs {
  endpoint: string;
  body?: BodyInit;
  headers?: HeadersInit;
  method?: 'DELETE' | 'GET' | 'PATCH' | 'POST';
  removeContentType?: boolean;
  getDetailedErrors?: boolean;
  throwDetailErrors?: boolean;
  isBlob?: boolean;
}

export class HttpError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'HttpError';
    this.statusCode = statusCode;
  }
}

// const userEmail = (): any => {
//   const token = localStorage.getItem(StorageKeys.Token);
//   if (token as string) {
//     const decodedToken = jwt_decode(token);
//     const email = JSON.stringify(decodedToken?.email<u);
//     return email;
//   }
// };

const fetcher = async <Data = unknown>(args: FetcherArgs): Promise<Data> => {
  const userProfile = useMultipleProfiles.getState().userProfile;

  const {
    endpoint,
    body,
    headers,
    method = 'GET',
    removeContentType,
    getDetailedErrors,
    isBlob = false,
  } = args;

  const url = `${API_URL}${endpoint}`;

  const waitForToken = async (): Promise<string> => {
    return await new Promise((resolve) => {
      try {
        const interval = setInterval(() => {
          const userToken = localStorage.getItem(StorageKeys.Token);
          if (userToken !== null) {
            clearInterval(interval);
            resolve(userToken);
          }
        }, 500);
      } catch (e) {
        Sentry.captureException(e);
      }
    });
  };

  const userToken = await waitForToken();

  const getUser = () => {
    try {
      const token = userToken;
      const userInfo: DecodedJWT = jwtDecode(token);
      const email = (userInfo.email as string) || null;

      return email;
    } catch (e) {
      Sentry.captureException(e);
    }
    return null;
  };

  const requestOptions = {
    body,
    headers: {
      ...headers,
    },
    method,
  };

  requestOptions.headers = {
    'Content-Type': 'application/json',
    Authorization: userToken == null ? '' : `Bearer ${userToken}`,
    'User-profile-id': userProfile?.id ?? '',
    ...headers,
  };

  if (removeContentType === true) {
    delete requestOptions?.headers?.['Content-Type'];
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    const user = getUser() as string;

    Sentry.setUser(null);

    if (getDetailedErrors) {
      const errorMessage = await response.json();
      Sentry.captureMessage(JSON.stringify(errorMessage));

      Sentry.setUser({
        email: user,
      });

      throw new Error(errorMessage.message, { cause: errorMessage.cause });
    }

    throw new HttpError(response.statusText, response.status);
  }

  return isBlob
    ? await (response.blob() as unknown as Promise<Data>)
    : await (response.json() as Promise<Data>);
};

export default fetcher;
